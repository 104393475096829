@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&display=swap');

html, body {   
    height: 100%;
    width:100%;
    box-sizing: border-box;
    margin:0;
    padding:0;
}

.bg-special {
    height:100%;
    width:100%;
    position: fixed;
    z-index:0;
    top:0;
    left:0;
   /* background-image: url("https://images.unsplash.com/photo-1594709589045-8fbc49be45c1?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1351&q=80");
    background-image: url("https://images.unsplash.com/photo-1466121239823-b0bdb9e64631?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
    background-image: url("https://images.unsplash.com/photo-1511651 432281-5cf2a44004f4?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1490&q=80");
    background-image: url("https://images.unsplash.com/photo-1571322270931-4202f63500a3?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2090&q=80");
    background-image: url("https://images.unsplash.com/photo-1500100586562-f75ff6540087?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1489&q=80");
    background-image: url("https://images.unsplash.com/photo-1572893532815-92ff0fb1874e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
    background-image: url("https://images.unsplash.com/photo-1572893532815-92ff0fb1874e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
    background-image: url("https://images.unsplash.com/photo-1471933449393-ae0b365e17c2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80");
     */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
}

.bg-orange {
    color:rgb(209, 209, 209);
    background-color: rgb(252, 130, 57);
}

.container {
    position: absolute;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.section {
    height: 100%;
    width:100%;
}

::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #ffffff00; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(165, 57, 57); 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

@media (orientation: landscape) {
    .bg-main {
        background: linear-gradient(to right, rgba(0,0,0,.9), rgba(0,0,0,.4) 70%, rgba(0,0,0,.8));
    }

    .mobile-menu-button{
        display:none;
    }

    /*NAVBAR*/
    .navbar {
        display: flex;
        position: fixed;
        z-index:97;
        top:0;
        left:0;
        width:100%;
        flex-direction: row;
    }

    .navbar .logotipo {
        flex:2.41;
        justify-content: center;
        align-items: center;
        text-align: left;
        padding:30px;
    }

    .navbar .logotipo img {

    }

    .navbar .menu {
        flex:8;
        display: flex;
        align-items: center;

    }

    .navbar .menu ul {
        display: flex;
        flex-direction: row;
    }

    .navbar .menu ul li {
        flex:1;
        padding:17px;
        display:inline;
    }

    .navbar ul li a {
        color:rgb(197, 194, 194);
        font-family: 'Bebas Neue', cursive;
        text-shadow:1px 1px rgb(54, 53, 53);
        letter-spacing:3px;
        font-size: 12px;
        text-decoration:none;
        outline: none;
        letter-spacing: 3px;
    }


    .navbar .especial {
        max-width:200px;
        min-width: 150px;
        flex:2.4;
        display: flex;
        flex-direction: row;
    }

    .navbar .especial .expandible {
        flex:1;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: 'Bebas Neue', cursive;
        letter-spacing:2px;
        font-size: 10px;
        border: none;
        background-color: transparent;
        color:white;
        outline:none;
        cursor: pointer;
    }

    .navbar .especial .expandible:hover {
        background-color: rgba(5, 5, 5, 0.534);
    }

    /*BACKS*/
    .back-1 {  
        flex:3;
        background-color:rgba(26, 25, 25, 0.863);
        min-width:200px;
    }

    .back-2 {
        flex:10.4;
    }

    /*DELIMITER*/
    .delimiter {
        display:flex;
        flex-direction: row;
        position: fixed;
        z-index:3;
        width: 100%;
        height:100%;
        top:0;
        left:0;
    }

    .delimiter .first {
        flex:1;
        min-width:180px;
        height:100%;
    }

    .delimiter .second {
        flex:4;
        display:flex;
        flex-direction: column;
        margin-top:0px;
        overflow:auto;
    }

    .delimiter .second .title {
        font-family: 'Bebas Neue', cursive;
        position: relative;
        justify-content: end;
        align-items: flex-end;   
    }

    .delimiter .second .title label {
        font-family: 'Bebas Neue', cursive;  
        color:white;
        font-size: 5vw;
        letter-spacing: 5px;
    }

    .delimiter .second .subtitle label {
        font-family: 'Pathway Gothic One', sans-serif;
        color:rgb(240, 240, 240);
        font-size: 2vw;
        font-weight: lighter;
        letter-spacing: 2px;
    }

    .delimiter .second .rollo {
        margin-top:30%;
    }

    .rollo .contenido {
        border-left:solid 1px rgb(49, 48, 48);
        min-height:150px;
        margin-top: 6%;
        display:flex;
        flex-direction:row;
        overflow-y: scroll;
    }

    .rollo .contenido .margen {
        width:100px;
        opacity:0;
    }

    .margen .cuadrito {
        height:40px;
        width:2px;
        background-color: rgb(196, 185, 185);
    }

    .rollo .contenido .testo {
        flex:1;
        max-width: 400px;
        
    }

    .rollo .contenido .testo p{
        margin:0 auto;
        font-family:  'Oswald', sans-serif;
        font-size: 15px;
        font-weight: 300;
        color:rgb(221, 210, 210);
        text-align: justify;
    }

    .delimiter .third {
        flex:3;
        display:flex;
    }

    .third .social {
        flex:1;
        color: white;
        font-family: 'Bebas Neue', cursive;
        position: relative;
    }
 
    .social ul {
        position: absolute;
        bottom: 20px;
        display: flex;
        flex-direction:row;
        width:80%;  
        right:0%;
    }

    .social ul li {
        display: inline;
        flex:1;
        text-align: center;
    }

    .social a {
        color:white;
        text-decoration: none;
    }
}

@media (orientation: portrait) {
    /*BACKS*/
    .back-1 {
        flex:3;
    }

    .back-2 {
        flex:10.4;
    }

    .bg-main {
        background: linear-gradient(to right, rgba(0,0,0,.9), rgba(0,0,0,.4) 70%, rgba(0,0,0,.8));
    }

    .container {
        display: flex;
        flex-direction: column;
    }

    .navbar {
        align-items: center;
        padding:12px;
        box-shadow: 0px 0px 10px rgb(196, 104, 68);
        text-align: center;
        background-color:rgb(19, 1, 1);
        display:flex;
    }

    .navbar .logotipo {
        flex:1;
    }

    .navbar .mobile-menu-button {
        flex:1;
        height:100%;
        align-items: center;
        display: flex;
    }

    .navbar .mobile-menu-button .buttonmenu {
        border-radius: 5px;
        border:none;
        float:left;
        height:80%;
        width:30%;
        box-shadow: 0px 0px 2px rgb(114, 112, 112);
        background-color: rgb(245, 241, 241);
    }

    .navbar .mobile-cato {
        flex:1;
    }

    .navbar .mobile-menu {
        background:rgba(0, 0, 0, 0.863);
        color:rgb(252, 252, 252);
        position:fixed;
        left:0;
        top:0;
        display: flex;
        height:100%;
        width:100%;
        z-index: 99;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top:20%;
    }

    .navbar .mobile-menu .close-button:after {
        content:"\00d7";
        display: inline-block;
        color:white;
        position:absolute;
        right:20px;
        top:15px;
        font-size:40px;
    }

    .navbar .mobile-menu ul {
        flex:1;
        flex-direction:column;
        list-style: none;
    }

    .navbar .mobile-menu ul li {
        padding-top:15px;

    }

    .navbar .mobile-menu ul a {
        text-decoration: none;
        color:white;
        font-family: 'Bebas Neue', cursive;
        font-size: 30px;
    }

    .navbar .mobile-menu ul a:active {
        color:orangered;
    }



    .navbar .menu {
        display:none;
    }

    .navbar .especial {
        display:none;
    }

    .delimiter .third {
        flex:3;
        display:flex;
        position:absolute;
        bottom:0;
        right:0;
    }

    .third .social {
      
        color: white;
        font-family: 'Bebas Neue', cursive;
        position: fixed;
        bottom:0;
        right:0;
        z-index:999;
        
    }
 
    .social ul {
        display: flex;
        flex-direction:row;
    }

    .social ul li {
        flex:1;
        text-align: center;
    }

    .social a {
        color:white;
        text-decoration: none;
    }

    .rollo .contenido {
        border-left:solid 1px rgb(49, 48, 48);
        min-height:150px;
        margin-top: 6%;
        display:flex;
        flex-direction:row;
        overflow-y: scroll;
    }

    .rollo .contenido .margen {
        width:100px;
        opacity:0;
    }

    .margen .cuadrito {
        height:40px;
        width:2px;
        background-color: rgb(196, 185, 185);
    }

    .rollo .contenido .testo {
        flex:1;
        max-width: 400px;
        
    }

    .rollo .contenido .testo p{
        margin:0 auto;
        font-family:  'Oswald', sans-serif;
        font-size: 15px;
        font-weight: 300;
        color:rgb(221, 210, 210);
        text-align: justify;
    }

    .delimiter .third {
        flex:3;
        display:flex;
    }

    .third .social {
        flex:1;
        color: white;
        font-family: 'Bebas Neue', cursive;
        position: relative;
    }

    .delimiter .second .title {
        font-family: 'Bebas Neue', cursive;
        position: relative;
        justify-content: end;
        align-items: flex-end;   
    }

    .delimiter .second .title label {
        font-family: 'Bebas Neue', cursive;  
        color:white;
        font-size: 15vw;
        letter-spacing: 5px;
    }

    .back-1 , .back-2 {
        display:none;
    }

    .delimiter .second .subtitle label {
        font-family: 'Pathway Gothic One', sans-serif;
        color:rgb(240, 240, 240);
        font-size: 2vw;
        font-weight: lighter;
        letter-spacing: 2px;
    }

}

